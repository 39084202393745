export const VOCAL_LISTING_START = "restart chat"
export const VOCAL_LISTING_END = "Go silent"
export const VOCAL_ACTION_START = /action start/gi
export const VOCAL_ACTION_END = /action stop/gi
export const UNCENSORED_WORDS = [
    "ass",
    "anal",
    "asshole",

    "butt",
    
    "bitch", // same
    "bitchy", // same
    
    "bitches", // same
    "big tits", // same
    "big black",
    "black cock",
    "big breasts",
    "big knockers",

    "cum",
    "cunt",  // same
    "cumming", // same
    "cumshots", // same
    "cocksucker",
    "cunnilingus",

    "dick",
    "dolcett",
    "dog style",
    "deepthroat", // same
    "deep throat", // same
    "doggie style",

    "erotic", // same
    "erotism",
    "ejaculation",

    "fuck",
    "fucking", // same
    "fingering", 
    "fingerbang",
    "fuck buttons",

    "gay sex",
    "genitals", // same
    "gang bang",
    "giant cock",

    "intercourse",

    "jizz",
    "juggs",
    "jigaboo",
    "jerk off",
    "jiggerboo",

    "masturbate",
    "masturbation",

    "nude",
    "nudity",
    "nipples",

    "orgy",
    "orgasm",

    "porn",
    "pussy",
    "pornography",

    "rape",
    "raping",

    "sex",
    "suck",
    "slutty",

    "tit",
    "tits",
    "titty",
    "titties",
    
    "whore",
]