import { Check, X } from 'lucide-solid'
import { Component, Show, JSX, createMemo, Switch, Match, createSignal } from 'solid-js'
import Button from './Button'
import './modal.css'
import Tabs from './Tabs'
import { AppSchema } from '/common/types'
import { chatStore } from '../store'

interface Props {
  title?: string | JSX.Element
  show: boolean
  children?: JSX.Element
  close: () => void
  footer?: JSX.Element
  maxWidth?: 'full' | 'half'
  fixedHeight?: boolean
  onSubmit?: (ev: Event & { currentTarget: HTMLFormElement }) => void
  tabs?: Array<{ name: string; content: JSX.Element }>

  /**
   * If set to false, the close button 'X' will be omitted
   */
  dismissable?: boolean
}

const Modal: Component<Props> = (props) => {
  const [tab, setTab] = createSignal(0)
  let ref: any

  const width = createMemo(() => {
    if (!props.maxWidth) return `sm:max-w-lg`

    return props.maxWidth === 'full' ? `sm:w-[calc(100vw-64px)]` : 'sm:w-[calc(50vw)]'
  })

  const minHeight = createMemo(() => (props.fixedHeight ? 'modal-height-fixed' : ''))

  const defaultSubmit = (ev: Event) => {
    ev.preventDefault
  }

  return (
    <Show when={props.show}>
      <div class="fixed inset-x-0 top-0 z-[100] items-center justify-center px-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 -z-10 opacity-40 transition-opacity">
          <div class="absolute inset-0 bg-black" />
        </div>
        <div class="modal-body">
          <form
            ref={ref}
            onSubmit={props.onSubmit || defaultSubmit}
            class={`modal-height bg-white z-50 my-auto w-[calc(100vw-16px)] overflow-hidden rounded-lg shadow-md shadow-black transition-all ${width()} `}
          >
            <Switch>
              <Match when={props.tabs}>
                <div class="flex h-[56px] flex-row justify-between text-lg">
                  <Tabs selected={tab} select={setTab} tabs={props.tabs!.map((t) => t.name)} />
                  <Show when={props.dismissable !== false}>
                    <div onClick={props.close} class="cursor-pointer p-4 text-gray-900">
                      <X />
                    </div>
                  </Show>
                </div>
              </Match>

              <Match when>
                <div class="flex flex-row justify-between p-4 text-lg font-bold">
                  <div class="text-gray-900">{props.title}</div>
                  <Show when={props.dismissable !== false}>
                    <div onClick={props.close} class="cursor-pointer text-gray-900">
                      <X />
                    </div>
                  </Show>
                </div>
              </Match>
            </Switch>

            {/* 132px is the height of the title + footer*/}
            <div class={`modal-content ${minHeight()} overflow-y-auto p-4 pt-0 text-lg`}>
              <Switch>
                <Match when={props.tabs}>{props.tabs![tab()].content}</Match>

                <Match when>{props.children}</Match>
              </Switch>
            </div>
            <Show when={props.footer}>
              <div class="flex w-full flex-row justify-end gap-2 p-4">{props.footer}</div>
            </Show>
          </form>
        </div>
      </div>
    </Show>
  )
}

export default Modal

export const NoTitleModal: Component<Omit<Props, 'title'>> = (props) => {
  let ref: any
  const width = createMemo(() => {
    if (!props.maxWidth) return `sm:max-w-lg`

    return props.maxWidth === 'full' ? `sm:w-[calc(100vw-64px)]` : 'sm:w-[calc(50vw)]'
  })

  const minHeight = createMemo(() =>
    props.fixedHeight ? `min-h-[calc(80vh-132px)] sm:min-h-[calc(90vh-132px)]` : ''
  )

  const defaultSubmit = (ev: Event) => {
    ev.preventDefault()
  }

  return (
    <Show when={props.show}>
      <div class="fixed inset-x-0 top-0  items-center justify-center px-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 -z-10 opacity-40 transition-opacity">
          <div class="absolute inset-0 bg-black" />
        </div>
        <div class="modal-body">
          <form
            ref={ref}
            onSubmit={props.onSubmit || defaultSubmit}
            class={`bg-900 my-auto max-h-[80vh] w-[calc(100vw-16px)] overflow-hidden rounded-lg shadow-md shadow-black transition-all sm:max-h-[90vh] ${width()} `}
          >
            <div class="flex flex-row justify-end pr-4 pt-4 text-lg font-bold">
              <div onClick={props.close} class="cursor-pointer">
                <X />
              </div>
            </div>

            {/* 132px is the height of the title + footer*/}
            <div
              class={`max-h-[calc(80vh-132px)] sm:max-h-[calc(90vh-132px)] ${minHeight()} overflow-y-auto text-lg`}
            >
              {props.children}
            </div>

            <Show when={props.footer}>
              <div class="flex w-full flex-row justify-end gap-2 p-4">{props.footer}</div>
            </Show>
          </form>
        </div>
      </div>
    </Show>
  )
}

export const ConfirmModal: Component<{
  show: boolean
  close: () => void
  confirm: () => void
  message: string | JSX.Element
}> = (props) => {
  const confirm = () => {
    props.confirm()
    props.close()
  }

  return (
    <Modal
      show={props.show}
      close={props.close}
      title="Confirmation"
      footer={
        <>
          <Button schema="secondary" onClick={props.close}>
            <X /> Cancel
          </Button>

          <Button onClick={confirm}>
            <Check /> Confirm
          </Button>
        </>
      }
    >
      <div class="mb-8 flex justify-center">{props.message}</div>
    </Modal>
  )
}

export const TitleModal: Component<{
  show: boolean
  close: () => void
  message: string | JSX.Element
}> = (props) => {
  return (
    <Modal
      show={props.show}
      close={props.close}
      title="Please Note"
      footer={
        <>
          
        </>
      }
    >
      <div class="mb-8 flex justify-center text-gray-900">{props.message}</div>
    </Modal>
  )
}

export const LackCreditModal: Component<{
  show: boolean
  close: () => void
  char?: AppSchema.Character
  type: string
}> = (props) => {
  
  const charId = props.char?.characterId;
  let encoded = btoa(`${charId}|1`);
  return (
    <div class={`w-[300px] fixed flex items-center space-x-4 text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 shadow left-5 bottom-[52px] space-x ${props.show ? '' : 'hidden'}`} role="alert">
        <div class="flex w-full">
            <div class="text-sm font-normal w-full">
                <div class="mb-1 text-md font-semibold text-white py-4 text-center bg-red-600 uppercase relative">
                  <p>{props.type === "text" ? "text credits all used!" : "voice credits all used!"}</p>
                  <span class='absolute top-[14px] right-[10px] cursor-pointer' onClick={props.close}><X /></span>
                </div>
                {props.type === "text" && (
                  <div class="mb-2 text-sm font-normal py-10 px-4">
                    You have used all your text credits.<br/>
                  </div> 
                )}
                {props.type === "voice" && (
                  <div class="mb-2 text-sm font-normal py-10 px-4">
                    You have used all your voice credits.<br/>
                    However, you can still chat with the AI as long as you have sufficient text credits.
                  </div> 
                )}
                <div class="pb-4 px-4">
                  <a href={`https://myhot.ai/user-purchase-credits.php?${encoded}`} class="w-full block px-2.5 py-4 text-md font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 text-center uppercase">
                    buy {props.type === "text" ? "text" : "voice"} credits now
                  </a>   
                </div>
            </div>
        </div>
    </div>
  )
}

export const SettingModal: Component<{
  show: boolean
  close: () => void
}> = (props) => {

  const setAutoListening = (auto: boolean) => {
    chatStore.setState({ 
      ...chatStore.getState(), 
      autoListening: auto
    })
  }

  const setSleepMode = (mode: boolean) => {
    chatStore.setState({ 
      ...chatStore.getState(), 
      sleepMode: mode,
      autoListening: true
    })
  }
  
  return (
    <Modal
      show={props.show}
      close={props.close}
      title="Setting"
      footer={
        <>
          
        </>
      }
    >
      <div class="flex items-center mb-4">
        <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" checked={chatStore.getState().autoListening} onChange={(e) => setAutoListening(e.target.checked)} />
        <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Hand free conversations instead?</label>
      </div>
      <div class="flex items-center mb-4">
        <input id="sleep_mode" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" checked={chatStore.getState().sleepMode} onChange={(e) => setSleepMode(e.target.checked)} />
        <label for="sleep_mode" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Voice-Activated Sleep Mode Command?</label>
      </div>
    </Modal>
  )
}