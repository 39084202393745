import gif from '../asset/logo.jpg'

const LogoIcon = () => {
  return (
    <>
      <img src={gif} alt="" class='h-[60px]' />
    </>
  )
}

export default LogoIcon