import { ImagePlus, Megaphone, PlusCircle, Send, Zap, MoreHorizontal } from 'lucide-solid'
import {
  Component,
  createMemo,
  createSignal,
  For,
  Match,
  onCleanup,
  Setter,
  Show,
  Switch,
} from 'solid-js'
import { AppSchema } from '../../../../common/types/schema'
import Button from '../../../shared/Button'
import { DropMenu } from '../../../shared/DropMenu'
import TextInput from '../../../shared/TextInput'
import { chatStore, toastStore, userStore } from '../../../store'
import { msgStore } from '../../../store'
import { SpeechRecognitionRecorder } from './SpeechRecognitionRecorder'
import { Toggle } from '/web/shared/Toggle'
import { defaultCulture } from '/web/shared/CultureCodes'
import { createDebounce } from '/web/shared/util'
import { useDraft, useEffect } from '/web/shared/hooks'
import { eventStore } from '/web/store/event'
import { useAppContext } from '/web/store/context'
import NoCharacterIcon from '/web/icons/NoCharacterIcon'
import WizardIcon from '/web/icons/WizardIcon'
import { EVENTS, events } from '/web/emitter'
import { AutoComplete } from '/web/shared/AutoComplete'
import { chatsApi } from '/web/store/data/chats'
import { AssemblyaiSTTRecorder } from './AssemblyaiSTTRecorder'

const InputBar: Component<{
  chat: AppSchema.Chat
  bots: AppSchema.Character[]
  botMap: Record<string, AppSchema.Character>
  char?: AppSchema.Character
  swiped: boolean
  showOocToggle: boolean
  ooc: boolean
  setOoc: Setter<boolean>
  send: (msg: string, ooc: boolean, onSuccess?: () => void) => void
  more: (msg: string) => void
  request: (charId: string) => void
}> = (props) => {
  let ref: HTMLTextAreaElement

  const [ctx] = useAppContext()

  const user = userStore()
  const state = msgStore((s) => ({ lastMsg: s.msgs.slice(-1)[0], msgs: s.msgs }))
  const chats = chatStore((s) => ({ replyAs: s.active?.replyAs }))
  const chats1 = chatStore((s) => ({
    lastId: s.lastChatId,
    voiceCredits: s.voiceCredits,
    textCredits: s.textCredits,
    paymentEnabled: s.active?.chat.paymentEnabled
  }))
  useEffect(() => {
    
    const listener = (text: string) => {
      setText('')
      setText(text)
    }

    events.on(EVENTS.setInputText, listener)

    return () => events.removeListener(EVENTS.setInputText, listener)
  })

  useEffect(() => {
    const getvoicemsg = async () => {
      if (chats1.lastId && chats1.paymentEnabled != "no") {
        const res = await chatsApi.getCredits(chatStore.getState().chatProfiles[0].userId, chatStore.getState().active?.char.characterId, chatStore.getState().active?.chat.whiteLabel?.toString())
        chatStore.setState({ 
          ...chatStore.getState(), 
          voiceCredits: res.totalVoiceCredit - res.consumedVoiceCredit,
          textCredits: res.totalTextCredit - res.consumedTextCredit,
        })
      }
    }
    getvoicemsg();
  })

  const draft = useDraft(props.chat._id)

  const toggleOoc = () => {
    props.setOoc(!props.ooc)
  }

  const isOwner = createMemo(() => props.chat.userId === user.user?._id)

  const [text, setText] = createSignal(draft.text)
  const [menu, setMenu] = createSignal(false)
  const [cleared, setCleared] = createSignal(0, { equals: false })
  const [complete, setComplete] = createSignal(false)
  const [listening, setListening] = createSignal(false)

  const completeOpts = createMemo(() => {
    const list = ctx.activeBots.map((char) => ({ label: char.name, value: char._id }))
    return list
  })

  const onCompleteSelect = (opt: { label: string }) => {
    setComplete(false)
    let prev = text()
    const before = prev.slice(0, ref.selectionStart - 1)
    const after = prev.slice(ref.selectionStart)
    const next = `${before}${opt.label}${after}`
    setText(next)
    saveDraft(next)
    ref.focus()
    ref.setSelectionRange(
      before.length + opt.label.length,
      before.length + opt.label.length,
      'none'
    )
  }

  const placeholder = createMemo(() => {
    if (props.ooc) return 'Send a message... (OOC)'
    if (chats.replyAs) return `Send a message to ${ctx.allBots[chats.replyAs]?.name}...`
    return `Send a message...`
  })

  const [saveDraft, disposeSaveDraftDebounce] = createDebounce((text: string) => {
    draft.update(text)
  }, 50)

  const updateText = () => {
    if (!ref) return
    const value = ref.value || ''
    setText(ref.value || '')
    saveDraft(value)
  }

  const send = () => {
    if (!ref) return

    const value = ref.value.trim() || text().trim()
    if (!value) return

    if (props.swiped) {
      return toastStore.warn(`Confirm or cancel swiping before sending`)
    }

    props.send(value, props.ooc, () => {
      ref.value = ''
      setText('')
      setCleared(0)
      draft.clear()
    })
  }

  onCleanup(() => {
    disposeSaveDraftDebounce()
  })


  return (
    <div class="relative flex items-center justify-center">
      <Show when={props.showOocToggle}>
        <div class="cursor-pointer p-2" onClick={toggleOoc}>
          <Show when={!props.ooc}>
            <WizardIcon />
          </Show>
          <Show when={props.ooc}>
            <NoCharacterIcon color="var(--bg-500)" />
          </Show>
        </div>
      </Show>

      <Show when={complete()}>
        <AutoComplete
          options={completeOpts()}
          close={() => setComplete(false)}
          onSelect={onCompleteSelect}
          dir="up"
          offset={44}
        />
      </Show>

      <TextInput
        fieldName="chatInput"
        isMultiline
        spellcheck
        lang={props.char?.culture}
        ref={ref! as any}
        value={text()}
        placeholder={placeholder()}
        parentClass="flex w-full"
        class="input-bar rounded-r-none"
        onKeyDown={(ev) => {
          if (ev.key === '@') {
            setComplete(true)
          }

          const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)
          const canMobileSend = isMobileDevice ? user.ui.mobileSendOnEnter : true
          if (ev.key === 'Enter' && !ev.shiftKey && canMobileSend) {
            if (complete()) return
            send()
            ev.preventDefault()
          }
        }}
        onInput={updateText}
      />
      
      {chats1.paymentEnabled != "no" && (
        <span class="bg-white h-full flex items-center border-l border-r border-gray-300">
          <p class="text-gray-600 text-sm px-2">{chats1.textCredits}/{chats1.voiceCredits}</p>
        </span>
      )}
      <span class="bg-white h-full flex items-center rounded-r-md">
        <Switch>
          <Match when={text() === '' || listening()}>
            {/* <SpeechRecognitionRecorder
              culture={props.char?.culture}
              onText={(value) => setText(value)}
              onSubmit={() => send()}
              cleared={cleared}
              listening={setListening}
            /> */}
            <AssemblyaiSTTRecorder 
              culture={props.char?.culture}
              onText={(value) => setText(value)}
              onSubmit={() => {send(); setText("")}}
              cleared={cleared}
              listening={setListening}
            />
          </Match>

          <Match when>
            <Button schema="clear">
              <Send class="icon-button" size={18} onClick={send} />
            </Button>
          </Match>
        </Switch>
      </span>
      
      {/* <button
        onClick={onButtonClick}
        class="h-full rounded-l-none rounded-r-md border-l bg-white px-2 py-2"
      >
        <ImagePlus size={18} class='text-black' />
      </button> */}
    </div>
  )
}

export default InputBar