import {
    Accessor,
    Component,
    Show,
    createEffect,
    createSignal,
} from 'solid-js'
import * as RecordRTC from 'recordrtc';
import { RealtimeTranscriber } from 'assemblyai';
import Button from '../../../shared/Button'
import { chatStore, msgStore, userStore } from '../../../store'
import MicImg from '../../../asset/mic.png'
import MicOnImg from '../../../asset/mic-on.png'
import Spinner from '../../../asset/spinner.gif'
import { AppSchema } from '../../../../common/types/schema'
import { VOCAL_LISTING_START, VOCAL_LISTING_END, VOCAL_ACTION_START, VOCAL_ACTION_END } from '../../../../client.config'
import { api } from "/web/store/api"

const defaultSettings: AppSchema.User['speechtotext'] = {
  enabled: true,
  autoRecord: true,
  autoSubmit: true,
}

let startRecording = false;

export function startSpeech() {
  startRecording = true;
}

export const AssemblyaiSTTRecorder: Component<{
    culture?: string
    class?: string
    onText: (value: string) => void
    onSubmit: () => void
    cleared: Accessor<number>
    listening: (state: boolean) => void
}> = (props) => {
  const settings = userStore((s) => ({ ...defaultSettings, ...s.user?.speechtotext }))
	let realtimeTranscriber: RealtimeTranscriber | null = null;
  let recorder: RecordRTC | null = null;
	const [currentText, setCurrentText] = createSignal('')
	const [isRecording, setIsRecording] = createSignal(false)
  const [isPendingRecording, setIsPendingRecording] = createSignal(false)

  createEffect(() => {
    const next = isRecording()
    props.listening(next)
  })

  let speakingStopped = 0
  let intervalForStopping: any = {}
  let startedLoop = false
  let submitStarted = false
  let loopEnded = false
  let recordingLoopTimer: any = null
	
  const getToken = async () => {
    const response1 = await api.get('/chat/assemblyToken');
    return response1.result.token;
  };

	const startTranscription = async () => {
    clearInterval(recordingLoopTimer)
    realtimeTranscriber = new RealtimeTranscriber({
      token: await getToken(),
      sampleRate: 16_000,
    });

    const texts: any = {};
    realtimeTranscriber.on('transcript', (transcript: any) => {
      let msg = '';
      texts[transcript.audio_start] = transcript.text;
      const keys = Object.keys(texts);
      keys.sort((a: any, b: any) => a - b);
      for (const key of keys) {
        if (texts[key]) {
          msg += ` ${texts[key]}`
        }
      }
      if (chatStore.getState().autoListening && chatStore.getState().sleepMode && msg.replace(/\./g, "").replace(/\ /g, "").toLowerCase().trim() === VOCAL_LISTING_START.replace(/\ /g, "").toLowerCase() && !chatStore.getState().activeListening) {
        setCurrentText('')
        props.onText("")
        endTranscription()
        chatStore.setState({activeListening: true})
        if (chatStore.getState().active?.chat._id) {
          const chatId: string = String(chatStore.getState().active?.chat._id);
          msgStore.send(chatId, VOCAL_LISTING_START, "send")
        }
        return
      }

      if (chatStore.getState().autoListening && chatStore.getState().sleepMode && msg.replace(/\./g, "").replace(/\ /g, "").toLowerCase().trim() === VOCAL_LISTING_END.replace(/\ /g, "").toLowerCase() && chatStore.getState().activeListening) {
        endTranscription()
        chatStore.setState({activeListening: false})
        setCurrentText('')
        props.onText("")
        if (chatStore.getState().active?.chat._id) {
          const chatId: string = String(chatStore.getState().active?.chat._id);
          msgStore.send(chatId, VOCAL_LISTING_END, "send")
        }
        return
      }
      // case of hand free listening and sleepmode, but activelistening is false
      if (chatStore.getState().autoListening && chatStore.getState().sleepMode && !chatStore.getState().activeListening) {
        if (msg.replace(/\./g, "").replace(/\ /g, "").length > 12 && !loopEnded) {
          loopEnded = true
          endTranscription()
          setCurrentText('')
          props.onText("")
          console.log("stopp")
          recordingLoopTimer = setInterval(() => {
            if (recorder == null) {
              startTranscription()
              loopEnded = false
            }
          }, 500)
        }
        return
      }

      if (currentText() != msg) {
        submitStarted = false;
        speakingStopped = 0;
        clearInterval(intervalForStopping)
        startedLoop = false;
      } else {
        if (!startedLoop && currentText() != "") {
          intervalForStopping = setInterval(() => {
            speakingStopped++
          }, 1000)
        }
        startedLoop = true
      }
      if (speakingStopped >= 5 && !submitStarted) {
        submitStarted = true
        props.onSubmit()
        props.onText("")
        endTranscription()
      }
      let startPattern = VOCAL_ACTION_START
      let endPattern = VOCAL_ACTION_END
      msg = msg.replace(startPattern, "*")
      msg = msg.replace(endPattern, "*")
      let pattern = /\*(.*?)\*/g;
      msg = msg.replace(pattern, (_, match) => `*${match.trim()}*`);
      if (!submitStarted) {
        setCurrentText(msg)
        props.onText(msg)
      }
    });

    realtimeTranscriber.on('error', (event: any) => {
      console.error(event);
      if (!realtimeTranscriber) return
      realtimeTranscriber.close();
      realtimeTranscriber = null;
    });

    realtimeTranscriber.on('close', (code: any, reason: any) => {
      console.log(`Connection closed: ${code} ${reason}`);
      realtimeTranscriber = null;
    });

    await realtimeTranscriber.connect();

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(async (stream) => {
        recorder = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm;codecs=pcm',
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 250,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1,
          bufferSize: 4096,
          audioBitsPerSecond: 128000,
          ondataavailable: async (blob: any) => {
            if(!realtimeTranscriber) return;
            const buffer = await blob.arrayBuffer();
            realtimeTranscriber.sendAudio(buffer);
          },
        });
        await recorder.startRecording();
      })
      .catch((err) => console.error(err));
    setIsRecording(true)
    setIsPendingRecording(false)
  }

	const endTranscription = async () => {
    if (!realtimeTranscriber) return
    await realtimeTranscriber.close();
    realtimeTranscriber = null;
    
    recorder.pauseRecording();
    recorder = null;
    setIsRecording(false)
    setIsPendingRecording(false)
  }

  const toggleListening = () => {
    const listening = isRecording()
    props.listening(true)
    if (listening) {
      setIsPendingRecording(true)
      endTranscription()
    } else {
      setIsPendingRecording(true)
      recordingLoopTimer = setInterval(() => {
        if (recorder == null) {
          startTranscription()
        }
      }, 500)
    }
  }

  createEffect(() => {
    setInterval(() => {
      if (startRecording) {
        startRecording = false;
        recordingLoopTimer = setInterval(() => {
          if (recorder == null) {
            startTranscription()
          }
        }, 500)
      }
    }, 1000)
  })

	return (
    <Show when={settings.enabled}>
      <Button
        schema="clear"
        onClick={toggleListening}
        size="sm"
      >
        {isPendingRecording() && (<span class="w-[32px]"><img src={Spinner} alt="" /></span>)}
        {!isPendingRecording() && !isRecording() && (<span class="w-[32px]"><img src={MicImg} alt="" /></span>)}
        {!isPendingRecording() && isRecording() && (<span class="w-[32px]"><img src={MicOnImg} alt="" class="w-[28px]" /></span>)}
      </Button>
    </Show>
	)
}
